<template>
<div>
Datenschutzerklärung
</div>
</template>

<script>
//  import FreezerList from '../components/manage-freezer/FreezerList'

  export default {
    name: 'Settings',

    components: {
//      FreezerList,
    },
  }
</script>
